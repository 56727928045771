<template>
  <v-container fill-height>
    <v-row justify="center" class="login-container px-5">
      <v-card width="500" class="login-card">
        <v-card-title>{{ forgotPassword ? 'Reset Password' : 'Login' }}</v-card-title>
        <v-card-text>
          <template v-if="!forgotPassword">
            <v-tabs v-model="loginType">
              <v-tab>Password</v-tab>
              <v-tab>Passwordless</v-tab>
            </v-tabs>
            <v-tabs-items v-model="loginType">
              <v-tab-item>
                <v-form>
                  <v-text-field v-model="signInEmail" type="email" label="Email" />
                  <v-text-field v-model="signInPassword" type="password" label="Password" />
                </v-form>
              </v-tab-item>
              <v-tab-item>
                <v-form>
                  <v-text-field v-model="signInEmail" type="email" label="Email" />
                </v-form>
              </v-tab-item>
            </v-tabs-items>
            <template v-if="mode == 'login' || loginType == 1">
              <v-btn type="submit" color="primary" @click="login">
                Login
              </v-btn>
              <template v-if="loginType == 0">
              or
              <a @click="mode = 'create'">Create Account</a>
              </template>
            </template>
            <template v-else>
              <v-btn
                type="submit"
                color="primary"
                @click="createAccount"
              >
                Create
              </v-btn>
              or
              <a @click="mode = 'login'">Login</a>
            </template>
          </template>
          <template v-else>
            <v-form>
              <v-text-field v-model="signInEmail" type="email" label="Email"></v-text-field>
              <v-btn color="primary" type="submit" @click="passwordReset">
                Submit
              </v-btn>
            </v-form>
          </template>
          <div class="forgot-password pt-4">
            <a @click="forgotPassword = !forgotPassword" color="accent">
              {{ forgotPassword ? '&larr; Back' : 'Forgot Password?' }}
            </a>
          </div>
        </v-card-text>
      </v-card>

      <div class="support-container py-10 px-5">
        <p>Looking for your schedule? Please visit:<br>
        <!--<a :href="scheduleLink">-->{{ scheduleText }}<!--</a>-->
        <br><br>
        <template v-if="globalSettings && globalSettings.supportEmail">
          Can't find your schedule link?<br>
          Please contact <a :href="`mailto:${globalSettings.supportEmail}`">{{globalSettings.supportEmail}}</a> with any questions.
        </template>
        </p>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import {
  getAuth,
  sendSignInLinkToEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth'
import { mapMutations, mapState } from 'vuex'
export default {
  data: () => ({
    mode: 'login',
    loginType: null,
    signInEmail: null,
    signInPassword: null,
    forgotPassword: null
  }),

  computed: {
    ...mapState(['globalSettings']),
    scheduleLink () {
      return `${window.location.origin}/schedule?author=all`
    },
    scheduleText () {
      return `${window.location.origin}/schedule?author=[your author id]`
    }
  },

  methods: {
    ...mapMutations(['setGlobalNotification']),

    async login () {
      switch (this.loginType) {
        case 0:
          console.log('Email/Password Login...')
          await this.emailPasswordLogin()
          break
        case 1:
          console.log('Passwordless login...')
          await this.sendLoginEmail()
          break
        default:
          console.log('Invalid login type!')
      }
    },

    async createAccount () {
      console.log('Create new account...')
      try {
        const res = await createUserWithEmailAndPassword(getAuth(), this.signInEmail, this.signInPassword)
        console.log(res)
        this.$router.push('/dashboard')
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: err.message, type: 'error' })
      }
    },

    async emailPasswordLogin () {
      try {
        const res = await signInWithEmailAndPassword(getAuth(), this.signInEmail, this.signInPassword)
        console.log(res)
        this.$router.push('/dashboard')
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: err.message, type: 'error' })
      }
    },

    async sendLoginEmail () {
      try {
        await sendSignInLinkToEmail(getAuth(), this.signInEmail, {
          url: `${window.location.origin}/schedule`,
          handleCodeInApp: true
        })
        window.localStorage.setItem('emailForSignIn', this.signInEmail)
        this.setGlobalNotification({ val: 'Sent sign in email.' })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error sending sign in email.', type: 'error' })
      }
    },

    async passwordReset () {
      try {
        await sendPasswordResetEmail(getAuth(), this.signInEmail)
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: err.message, type: 'error' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  flex-direction: column;
  max-width: 500px;
}
.login-card {
  margin: 0 auto;
}
.support-container {
  text-align: center;
}
.forgot-password {
  text-align: center;
}
</style>
